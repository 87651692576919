

















































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import echarts from "echarts";
import MainHeader from "@/components/main-header/main-header.vue";
import { PostShitiHengji, GetShuxinglaiyuanOptions } from "@/request/check";
import {
  AddShiti,
  GetShitiList,
  GetLeftCategory,
  SearchLeftCategory,
} from "@/request/storehouse";
import { GetTupu } from "@/request/tupu";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private cTab: any = "通用";
  private type: any = ""; // 有值表示是校验痕迹详情，校验痕迹详情的接口不一样
  private source: any = ""; // 来源页面
  private kind: any = ""; // 知识库类型
  private id: any = "";
  private ifShowShangweigainian: any = false;
  private data: any = {
    上位概念: [],
  };
  private tupuData: any = {};
  private tijiaoData: any = {
    select: [],
    text: "",
  };
  private ifShowSaveConfirm: any = false;
  private ifShowTijiao: any = false;
  private curSourceData: any = {};
  private ifShowSource: any = false;
  private curSource: any = ""; //添加来源页面的值
  private sources: any = [];
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private props: any = {
    label: "name",
  };
  private ifShowTree: any = false;
  private expandedNode: any = [];
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private loadNode(node: any, resolve: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        _id: node.data ? node.data._id : "",
        语义id: this.data["语义id"],
      },
    };
    GetLeftCategory(this, params, loading)
      .then((res: any) => {
        loading.close();
        let d: any = [];
        res.forEach((ele: any) => {
          if (ele.id === this.data.id) {
            ele.disabled = true;
          }
        });
        resolve(res);
      })
      .catch(() => {
        loading.close();
        resolve([]);
      });
  }
  private handleCheckChange(data: any, checked: any, indeterminate: any) {
    const checkData: any = (this.$refs.optTree as any).getCheckedNodes();
    this.data["上位概念"] = checkData;
    this.$forceUpdate();
  }
  private openSource(item: any) {
    this.curSourceData = item;
    this.ifShowSource = true;
    this.curSource = "";
  }
  private addSource() {
    this.curSourceData.source = this.curSource;
    this.getSources();
    this.ifShowSource = false;
  }
  private forceUpdate() {
    this.$forceUpdate();
  }
  private deleteSource(item: any) {
    if (!this.ifCompose) {
      return;
    }
    item.source = "";
  }
  private remoteSource(e: any) {
    this.getSources(e);
  }
  private getSources(e?: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetShuxinglaiyuanOptions(this, params).then((res: any) => {
      this.sources = res;
    });
  }
  private clickTijiao() {
    if (!this.data["实体名称"]) {
      this.$message.warning("实体名称缺失，请补充完善后提交！");
      return;
    }
    this.tijiaoData = {
      select: [],
      text: "",
    };
    this.ifShowTijiao = true;
  }
  private changePart(index: any) {
    const parant: any = document.getElementById("scrollBox");
    const children: any = document.getElementById("scrollBox" + index);
    if (parant) {
      if (children) {
        const parentTop = parant.offsetTop;
        const childTop = children.offsetTop;
        parant.scrollTop = childTop - parentTop - 14;
      }
    }
  }
  private input(e: any) {
    const index = this.tijiaoData.select.indexOf("其它");
    if (index === -1) {
      this.tijiaoData.select.push("其它");
    }
  }
  private tijiaoSure() {
    if (this.tijiaoData.select.length == 0) {
      this.$message.warning("请先选择本次修改的内容再提交");
      return;
    }
    const index = this.tijiaoData.select.findIndex((v: any) => {
      return v === "其它";
    });
    let result: any = [];
    if (index === -1) {
      result = this.tijiaoData.select;
    } else {
      if (this.tijiaoData.text) {
        result = this.tijiaoData.select;
        result[index] = "其它，" + this.tijiaoData.text;
      } else {
        result = this.tijiaoData.select;
      }
    }
    this.data.texts = result.join("、");
    AddShiti(this, this.data).then((res: any) => {
      this.$message.success("提交成功！");
      this.back();
    });
  }
  private tijiao() {
    this.ifShowSaveConfirm = false;
    this.clickTijiao();
  }
  private back() {
    this.$store.commit("updateIfCompose", false);
    this.$router.back();
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.ifCompose) {
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private drawTupu(d: any) {
    const params: any = {
      params: {
        entity_id: d.entity_id,
        entity_name: d.entity_name,
        entity_type: d.entity_type,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetTupu(this, params).then((data: any) => {
      loading.close();
      this.tupuData = data;
      data.nodes.forEach((ele: any, index: any) => {
        if (ele.type === "目标") {
          ele.symbol = "roundRect";
          ele.symbolSize = 25;
        } else if (ele.type === "实体") {
          ele.symbolSize = 20;
        } else {
          ele.symbolSize = 1;
        }
      });
      const Chart: any = echarts.init(this.$refs.Echarts1 as HTMLCanvasElement);
      const options: any = {
        color: this.colors,
        title: {
          text: "",
          subtext: "",
          top: "bottom",
          left: "right",
        },
        tooltip: {},
        legend: [
          {
            // selectedMode: 'single',
            data: data.categories.map(function (a: any) {
              return a.name;
            }),
          },
        ],
        animationDuration: 1500,
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            name: "",
            type: "graph",
            layout: "force",
            data: data.nodes,
            links: data.links,
            categories: data.categories,
            zoom: 3,
            roam: true,
            label: {
              show: true,
              position: "inside",
              formatter: "{b}",
            },
            edgeLabel: {
              show: true,
              formatter: (res: any) => {
                return res.data.name;
              },
            },
            edgeSymbol: ["", "arrow"],
            lineStyle: {
              color: "source",
              width: 1,
              curveness: 0.1,
            },
            emphasis: {
              show: false,
              focus: "adjacency",
              lineStyle: {
                width: 6,
              },
            },
          },
        ],
      };
      Chart.setOption(options, true);
      // 点击事件
      Chart.off("click");
      Chart.on("click", (p: any) => {
        const data: any = {
          entity_id: p.id,
          entity_name: p["name"],
          entity_type: p["category"],
        };
        // this.drawTupu(data);
      });
    });
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    if (!this.type) {
      const params: any = {
        params: {
          table_name: this.kind,
          id: this.id,
          need_attr_split: true,
        },
      };
      GetShitiList(this, params)
        .then((res: any) => {
          loading.close();
          this.data = res;
          this.chuliData();
          const d: any = {
            entity_id: res.id,
            entity_name: res["实体名称"],
            entity_type: res["语义类型"],
          };
          this.drawTupu(d);
          // 判断有没有上位概念，如果有需要显示上位概念的修改框
          const params1: any = {
            params: {
              _id: "",
              语义id: this.data["语义id"],
            },
          };
          GetLeftCategory(this, params1).then((msg: any) => {
            if (msg.length > 0) {
              this.ifShowTree = true;
              this.$nextTick(() => {
                if (this.data["上位概念"] && this.data["上位概念"].length > 0) {
                  let ids: any = [];
                  this.data["上位概念"].forEach((ele: any) => {
                    ids.push(ele["id"]);
                  });
                  this.expandedNode = ids;
                  // 选中
                  console.log(999);
                  console.log(this.data["上位概念"]);
                  (this.$refs.optTree as any).setCheckedNodes(this.data["上位概念"]);
                  (this.$refs.optTree as any).setCheckedKeys(ids);
                  const checkData: any = (this.$refs.optTree as any).getCheckedNodes();
                  console.log(checkData)
                }
              });
            }
          });
        })
        .catch((res: any) => {
          loading.close();
        });
    } else {
      const params: any = {
        history_id: this.id,
      };
      PostShitiHengji(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.data = res;
          const data: any = {
            entity_id: res.id,
            entity_name: res["实体名称"],
            entity_type: res["语义类型"],
          };
          this.drawTupu(data);
          this.chuliData();
        })
        .catch((res: any) => {
          loading.close();
        });
    }
  }
  private chuliData() {
    if (this.ifCompose) {
      this.data.attributes.forEach((element: any) => {
        if (element.contents.length == 0) {
          element.contents.push({
            text: "",
            source: "",
          });
        }
      });
      this.data.parent_attributes.forEach((element: any) => {
        if (element.contents.length == 0) {
          element.contents.push({
            text: "",
            source: "",
          });
        }
      });
    }
  }
  private addOne(item: any) {
    item.push({
      text: "",
      source: "",
    });
  }
  private deleteOne(item: any, i: any) {
    item.splice(i, 1);
  }
  private mounted() {
    if (this.$route.query.kind) {
      this.kind = this.$route.query.kind;
    }
    if (this.$route.query.source) {
      this.source = this.$route.query.source;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
  }
}
